import React, { useEffect, useRef, useState } from "react";
import "./ProdDropzone/AddProducts.css";
import ProductListPage from "../Product/ProductListPage/ProductListPage";
import ProductCategoryListPage from "../ProductCategory/ProductCategoryListPage/ProductCategoryListPage";
import IngredientListPage from "../Ingredient/IngredientListPage/IngredientListPage";
import Switch from "@mui/material/Switch";
import CustomTab from "../../components/CustomTab/CustomTab";

import SalePointListPage from "../Franchise/SalePointList/SalePointListPage";
import "../../index.scss";
import { FormattedMessage } from "react-intl";
import { URL_DOMAIN, URL_P2, URL_REPORT, URL_HELP } from "../../services/globals";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgress, MenuItem, Select } from "@material-ui/core";
import DownloadLinkCustom from "../../components/DownloadLinkCustom/DownloadLinkCustom";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DescriptionIcon from "@mui/icons-material/Description";
import DropzoneFile from "../../components/DropzoneFile/DropzoneFile";
import ProdDropzone from "./ProdDropzone/ProdDropzone";
import { getFranchiseTypes } from "../../services/utilsFranchiseType/utilsFranchiseType";
import { activateSpecialPrices, disactivateSpecialPrices, getExcelTemplate, uploadProducts, uploadProductsExcel } from "../../services/utilsProduct/utilsProduct";
import { useDetectOutsideClick } from "../../components/useDetectOutsideClick";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { styled, Box } from "@mui/system";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { set } from "date-fns";

import SettingsIcon from '@mui/icons-material/Settings';
import FeedbackAlert from "../../components/FeedbackAlert/FeedbackAlert";


export default function MyProductPage() {

    const [importOpen, setImportOpen] = useState(false);

    const [specialPricesOn, setSpecialPricesOn] = useState(false);

    const [downloadLink, setDownloadLink] = useState(null);

    const [openModal, setOpenModal] = useState(false);

    const [menuVisible, setMenuVisible] = useState(false);

    const onClickSettings = () => { setMenuVisible(!menuVisible) };

    const handleClose = () => { setImportOpen(true); setOpenModal(!openModal) };

    const handleSpecialPricesOn = () => { setSpecialPricesOn(!specialPricesOn) };

    const [activateUpdatedSuccess, setActivateUpdatedSuccess] = useState(false);

    const [messageId, setMessageId] = useState("sptUpdatedSuccess");

    const [settingsLoading, setSettingsLoading] = useState(false);

    const components = [
        {
            navTitle: "productsNav",
            component: <ProductListPage />,
            url: "productos",
            buttonComponenet: <PopupMenu active={importOpen} />
        },

        {
            navTitle: "categoriesNav",
            component: <ProductCategoryListPage />,
            url: "categorias-de-producto",
        },
    ];

    const style = {
        position: "absolute",
        width: "100%",
        height: "100%",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        display: "grid",
        placeItems: "center",
    };

    const activateSpecialPricesClick = () => {
        setSettingsLoading(true);
        console.log("Activar precios especiales");
        setMessageId("specialPricesActivatedSuccess");
        let jsonBody = { "fk_organization": localStorage.getItem("fk_organization") }
        activateSpecialPrices(JSON.stringify(jsonBody)).then((data) => {
            console.log("se logro activar los precios especiales");
            setActivateUpdatedSuccess(true);
            setSettingsLoading(false);
        });
    };

    const disactivateSpecialPricesClick = () => {
        setSettingsLoading(true);
        setMessageId("specialPricesDisctivatedSuccess");
        let jsonBody = { "fk_organization": localStorage.getItem("fk_organization") }
        disactivateSpecialPrices(JSON.stringify(jsonBody)).then((data) => {
            console.log("se logro desactivar los precios especiales");
            setActivateUpdatedSuccess(true);
            setSettingsLoading(false);
        });
    };

    return (
        <div>
            <main className="section-body scrolling">
                <div className="flex space row">
                    <div className="col-10">
                        <h1 className="section-title">
                            <FormattedMessage id="productTitle" />
                        </h1>
                    </div>
                    <div className="col-2 d-flex">
                        {menuVisible && (
                            <div style={{
                                position: 'absolute',
                                top: '18%',
                                right: '60px',
                                marginLeft: '10px',
                                border: '1px solid #CCCCCC',
                                backgroundColor: 'rgba(255, 255, 255, 0.9)', // Utilizamos un color de fondo semi-transparente
                                zIndex: 1,
                                borderRadius: '20px 0 20px 20px',
                                padding: '10px',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)'
                            }}>
                                {!settingsLoading ?
                                    <div>
                                        <lu>
                                            <li className="special-prices-li" style={{
                                                padding: '10px'
                                            }} onClick={activateSpecialPricesClick}> Activar Precios Especiales </li>
                                            <li className="special-prices-li" style={{ padding: '10px' }} onClick={disactivateSpecialPricesClick}> Desactivar Precios Especiales</li>
                                        </lu>
                                    </div> :
                                    <div>
                                        <CircularProgress></CircularProgress></div>
                                }

                            </div>
                        )}
                        <SettingsIcon onClick={onClickSettings} className="menu-trigger-prod-cat" />

                    </div>
                </div>
                <FeedbackAlert
                    idmessage={messageId}
                    open={activateUpdatedSuccess}
                    setOpen={setActivateUpdatedSuccess}
                />

                {components && <CustomTab props={components} />}
            </main>
        </div>
    );
}

function PopupMenu(props) {

    const [isActive, setIsActive] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);

    const fileInputRef = useRef(null);

    const handleFileSelect = () => {
        fileInputRef.current.click();
    };

    const handleInputChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const onClick = () => {
        console.log("click");
        console.log(!isActive);
        // console.log(dropdownRef.current);

        setIsActive(prevState => !prevState);
    };

    const handleDownload = async () => {
        try {
            let fkOrganization = localStorage.getItem("fk_organization");
            const response = await getExcelTemplate(fkOrganization)
            const blob = await response.blob();

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            document.body.appendChild(a);
            a.href = url;
            a.download = 'plantilla productos ontrack market.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error al descargar el archivo", error);
        }
    };
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        if (selectedFile === null) {
            // console.log("clarrrooo");
        } else {
            setIsLoading(true)
            const formData = new FormData();
            formData.append("fk_organization", localStorage.getItem("fk_organization"));
            formData.append("excel_file", selectedFile);
            uploadProductsExcel(formData).then((data) => {
                setIsLoading(false);
                window.location.reload();
            });
        }
    }, [selectedFile])

    useEffect(() => {
        const closeMenu = (event) => {
            if (!event.target.closest('.menu-trigger')) {
                setIsActive(false);
            }
        };

        window.addEventListener('click', closeMenu);

        return () => {
            window.removeEventListener('click', closeMenu);
        };
    }, []);

    return (
        <div className="container">
            <div className="menu-container">
                <button onClick={onClick} className="menu-trigger">
                    <Fab size="small" className="fab" color="secondary" aria-label="edit">
                        <AddIcon className="fab-icon" />
                    </Fab>
                </button>
                <nav
                    // ref={dropdownRef}
                    className={`menu ${isActive ? "active" : "inactive"}`}
                >
                    <ul>
                        <li>
                            <a href="productos/create">Nuevo producto</a>
                        </li>
                        <li>
                            <a href="#" onClick={handleDownload}>Descargar Plantilla</a>
                        </li>
                        <li>
                            <a href="#" onClick={handleFileSelect}>Importar productos</a>
                            <div>

                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleInputChange}
                                    accept=".xlsx"
                                />
                            </div>
                        </li>

                    </ul>
                </nav>
            </div>
            <FloatingLoadingMessage isLoading={isLoading} />
        </div>
    );
}


const FloatingLoadingMessage = ({ isLoading }) => {
    return (
        isLoading && (
            <div className="floating-loading-message-prod">
                <div className="loading-bar-prod"></div>
                <p>Cargando Productos...</p>
            </div>
        )
    );
};


