import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LinearProgress } from "@material-ui/core";
import EditPrimaryIcon from "../../assets/icons/edit-24px.svg";
import CustomTable from "../../components/Table/Table";
import SearchBar from "../../components/searchBar/searchBar";
import { getInventoryTemplatesOrganization } from "../../services/utilsProductInventory";
import Context from "../../context/UserContext";
import "../../index.scss";
import "../../globalFormStyle.scss";
import "./InventoryPage.scss";

const TemplateHome = () => {
    
  const { setSalePointOrigin, setSalePointDestination, setTypeInventoryTemplate } = useContext(Context);
  const [ rawProductsData, setRawProductsData ] = useState([]);
  const [ tableRows, setTableRows ] = useState([]);
  const [ searchQuery, setSearchQuery ] = useState("");
  const [linealLoading, setLinealLoading] = useState(false);
    
  useEffect(() => {
    const fkOrganization = localStorage.getItem("fk_organization");
    fetchInventoryTemplates(fkOrganization)
  }, [])
    
  useEffect(() => {
    if (rawProductsData) {
      let dataReady = rawProductsData.map((template) => {
        let rowData;

        rowData = createRow(
          template.id,
          template.name,
          template.type,
          template.fk_sale_point_origin,
          template.fk_sale_point_origin_name,
          template.fk_sale_point_destiny,
          template.fk_sale_point_destiny_name,
        )

        return rowData;
      });
      setTableRows(dataReady);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawProductsData]);
    
  const filterData = (rows) => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = rows.filter(
      (row) => {
        return row.name.toLowerCase().includes(lowercasedQuery);
      }
    );
    return filtered;
  };
      
  const fetchInventoryTemplates = async (fkOrganization) => {
    try {
      // Set loading state
      setLinealLoading(true);

      // Call API to get inventory template for the selected organization
      const response = await getInventoryTemplatesOrganization(fkOrganization);

      // Set raw data in the state
      setRawProductsData(response.templates);

      // Clear loading state
      setLinealLoading(false);
    } catch (error) {
        // Handle error
        console.error("Error fetching product current inventory:", error);
    }
  };
    
  const handleSelectInventoryTemplate = (id, type, source, source_name, destination, destination_name) => {
    saveTemplateId(id);
    setSalePointOrigin({id: source, name: source_name});
    setSalePointDestination({id: destination, name: destination_name});
    setTypeInventoryTemplate(type);
  }
    
  const createRow = (id, name, type, source, source_name, destination, destination_name) => {
    const updateType = type === '0' ? 'Actualización Forzada' : 'Traslado';
    const destination_sp = type === '0' ? '-' : destination_name;
  
    return {
      name,
      updateType,
      source_name,
      destination_sp,
      actions: (
        <Link to={`/inventarios/${id}/edit`} onClick={() => handleSelectInventoryTemplate(id, type, source, source_name, destination, destination_name)}><img src={EditPrimaryIcon} alt="edit"/></Link>
      ),
    };
  };
    
  const saveTemplateId = (id) => {
    localStorage.setItem("template_id", id);
  };
    
  const renderModeTable = () => {
    let inventoryTableHeaders;
    inventoryTableHeaders = [
      "nameHeader",
      "typeHeader",
      "sourceSalePointHeader",
      "destinationSalePointHeader",
      "actionsHeader",
    ];
          
    return (
      <CustomTable
        stickyHeader
        filtered={filterData}
        headers={inventoryTableHeaders}
        rows={tableRows}
        pagination={""}
      />
    );
  }
        
  const handleTextSearch = (event) => {
    setSearchQuery(event);
  };

  return (
    <main className="section-body inventory-container">
      <div className="flex">        
        <div>
          <Link to="/inventarios">
            <ArrowBackIcon></ArrowBackIcon>
          </Link>
        </div>
        <div>
          <h1 className="section-title">
            <FormattedMessage id="templateTitle" />
          </h1>
        </div>
      </div>
          
          
      <section>
        <SearchBar
          input={searchQuery}
          onChange={handleTextSearch}
          nombre={"Nombre"}
        />
      </section> 
    
      {/* Loading indicators */}
      <div className="headers-wrapper">
        {linealLoading && <LinearProgress />}
      </div>

      {/* Table */}
      <div className="table-wrapper">
        { 
          tableRows &&
          !linealLoading &&
          renderModeTable()
        }
      </div>
    </main>
  );
};

export default TemplateHome;
