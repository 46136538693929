import React, { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Select } from "@mui/material";
import { LinearProgress, MenuItem } from "@material-ui/core";
import Context from "../../../context/UserContext";
import { editTemplate, getTemplate } from "../../../services/utilsProductInventory";
import { getAllSalePoint } from "../../../services/utilsSalePoint";
import CustomTable, { convertToTextField } from "../../../components/Table/Table";
import SearchBar from "../../../components/searchBar/searchBar";
import FeedbackAlert from "../../../components/FeedbackAlert/FeedbackAlert";
import "../../../globalFormStyle.scss";

export const TemplateForm = () => {
  
  const { salePointOrigin, salePointDestination, typeInventoryTemplate } = useContext(Context);  
  const [ rawTemplateData, setRawTemplateData ] = useState();
  const [ tableRows, setTableRows ] = useState([]);
  const [ searchQuery, setSearchQuery ] = useState("");
  const [ salePoints, setSalePoints ] = useState([]);
  const [ selectedSalePointSource, setSelectedSalePointSource ] = useState();
  const [ selectedSalePointDestination, setSelectedSalePointDestination ] = useState();
  const [ modeStatus, setModeStatus] = useState(parseInt(typeInventoryTemplate));
  const [ openFeedbackAlert, setOpenFeedbackAlert ] = useState(true);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [messageId, setMessageId] = useState({id: "", type: "warning"});
  const [linealLoading, setLinealLoading] = useState(false);
  
  const productsUpdate = useRef([]);
  
  useEffect(() => {
    if (rawTemplateData) {
      let dataReady = rawTemplateData.products.map((product) => {
        let rowData;
        
        rowData = createRow(product.image, product.name, product.sku, product.quantity, product.fk_product)
        
        return rowData;

      });
      setTableRows(dataReady);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawTemplateData]);
  
  const handleChangeCurrentInventoryMode1 = (event, rows, rowId) => {
    let eventValue = parseInt(event.target.value === '' ? 0 : event.target.value);
    let jsonRow = { fk_product: rowId, quantity: eventValue };

    if (eventValue >= 0) {
      let newRows = rows.map((row) => {
        if (row.currentInventory[3] === rowId) {
          row.currentInventory[0] = event.target.value;
        }
        return row;
      });

      setTableRows(newRows);
    }

    let existingProductIndex = productsUpdate.current.findIndex(
      (product) => product.fk_product === rowId
    );

    if (existingProductIndex !== -1) {
      productsUpdate.current[existingProductIndex] = {
        ...productsUpdate.current[existingProductIndex],
        ...jsonRow,
      };
    } else {
      // Item doesn't exist, add it to updatedProducts
      productsUpdate.current.push(jsonRow);
    }
  };
  
  const createRow = (image, name, sku, quantity, id) => {

    return {
      image,
      name,
      sku,
      currentInventory: convertToTextField(quantity, "currentInventory", handleChangeCurrentInventoryMode1, id)
    };
  };
  
  const fetchData = async () => {
    
    const template_id = localStorage.getItem("template_id")
    let fkOrganization = localStorage.getItem("fk_organization");
    
    setLinealLoading(true)
    
    getAllSalePoint(fkOrganization).then((data) => {
      let sptOptions = data["sale_points"].map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setSalePoints(sptOptions);
      setSelectedSalePointSource(salePointOrigin.id)
      setSelectedSalePointDestination(salePointDestination.id)
    });
    
    getTemplate(template_id).then((data) => {
      setRawTemplateData(data)
      setLinealLoading(false)
    });
    
  };

  useEffect(() => {
    fetchData().then(() => {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const filterData = (rows) => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = rows.filter(
      (row) => {
        return row.name.toLowerCase().includes(lowercasedQuery) ||
        (row.sku && row.sku.toLowerCase().includes(lowercasedQuery));
      }
    );
    return filtered;
  };
  
  const handleTextSearch = (event) => {
    setSearchQuery(event);
  };
  
  const renderModeTable = () => {
    let inventoryTableHeaders;
    inventoryTableHeaders = [
      "emptyHeader",
      "productHeader",
      "skuHeader",
      "quantityHeader"
    ];
       
    return (
      <CustomTable
        stickyHeader
        filtered={filterData}
        headers={inventoryTableHeaders}
        rows={tableRows}
        rowTypes={[
          [0, "image"],
          [3, "textfield-number"]
        ]}
        pagination={""}
      />
    );
  }
    
  const handleSubmitNewInventory = (e) => { 
    e.preventDefault();
    
    const template_id = localStorage.getItem("template_id")
    setLinealLoading(true);
      
    const jsonBody = {
      inventory_template_id: template_id,
      fk_sale_point_origin: selectedSalePointSource,
      fk_sale_point_destiny: selectedSalePointDestination,
      type: modeStatus,
      update_inventory_template: 1,
      products: productsUpdate.current,
    };
    
    editTemplate(JSON.stringify(jsonBody)).then(
      (data) => {
        setLinealLoading(false)
        setMessageId({id: "templateEditedSuccess", type: "success"});
        setOpenFeedback(true);
        productsUpdate.current = [];
      }
    );
  };

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------
  return (
    <main className="section-body inventory-container">
      <div className="flex">
        <div>
          <h1 className="section-title">
            <FormattedMessage id="templateTitle" />
          </h1>
        </div>
      </div>
            
      <div className="headers-wrapper">
        {/* Selectors */}
        <div className="row">
          <div className="col-3 mb-4">
            <label className="form-label" htmlFor="modeInput">
              <FormattedMessage id="modelbl" />
            </label>
            <Select
              required
              value={modeStatus}
              name="mode"
              onChange={(e) => setModeStatus(e.target.value)}
              className="worker-form-select"
              id="modeInput"
            >
              <MenuItem key={0} value={0}>
                Actualización Forzada
              </MenuItem>
              <MenuItem key={1} value={1}>
                Traslado
              </MenuItem>
            </Select>
          </div>
          <div className="col-3 mb-4">
            <label className="form-label" htmlFor="salePointInput">
              <FormattedMessage id="franchiseLbl" />
            </label>
            <Select
              required
              value={selectedSalePointSource ? selectedSalePointSource : ""}
              name="fk_sale_point"
              onChange={(e) => setSelectedSalePointSource(e.target.value)}
              className="worker-form-select"
              id="salePointInput"
            >
              {salePoints.map((salePoint, i) => (
                <MenuItem key={i} value={salePoint.value}>
                  {salePoint.label}
                </MenuItem>
              ))}
            </Select>
          </div>
          {modeStatus === 1 && (
            <div className="col-3 mb-4">
              <label className="form-label" htmlFor="destSalePointInput">
                <FormattedMessage id="destiantionSalePointLbl" />
              </label>
              <Select
                required
                value={
                  selectedSalePointDestination
                    ? selectedSalePointDestination
                    : ""
                }
                name="destinationSalePoint"
                onChange={(e) =>
                  setSelectedSalePointDestination(e.target.value)
                }
                className="worker-form-select"
                id="destSalePointInput"
                // disabled={editingInventory}
              >
                {salePoints
                  .filter((salePoint) => salePoint.value !== selectedSalePointSource)
                  .map((salePoint, i) => (
                    <MenuItem key={i} value={salePoint.value}>
                      {salePoint.label}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          )}
          
          <div className="col mb-2">
            <form className="h-100">
              <div className="row justify-content-end">
                <div className="col-12 d-flex justify-content-end align-items-end flex-wrap">
                  {/* {editingInventory && ( */}
                    <>
                      <button
                        className="btn btn-primary me-2"
                        type="submit"
                        onClick={handleSubmitNewInventory}
                      >
                        <FormattedMessage id={"savebutton"} />
                      </button>
                      <button
                        className="btn btn-outline-primary me-2"
                        // onClick={handleCancelEdit}
                      >
                        <FormattedMessage id="cancelLbl" />
                      </button>
                    </>
                  {/* )} */}
                </div>
              </div>
            </form>
          </div>
          
        </div>

        <section>
          <SearchBar
            input={searchQuery}
            onChange={handleTextSearch}
            nombre={"Producto / SKU"}
          />
        </section>

        {linealLoading && <LinearProgress />}
      </div>
      <div className="table-wrapper">
        { 
          tableRows &&
          !linealLoading &&
          renderModeTable()
        }

        {openFeedbackAlert && (
          <FeedbackAlert
            type="success"
            message={<FormattedMessage id="inventoryCreated" />}
            onClose={() => setOpenFeedbackAlert(false)}
          />
        )}
      
        <Feedback
          type={messageId.type}
          idmessage={messageId.id}
          open={openFeedback}
          setOpen={setOpenFeedback}
        />
      </div>
    </main>
  );
};

const Feedback = ({
  idmessage,
  open,
  setOpen,
  type = "success",
  duration = 3000,
}) => {
  useEffect(() => {
    const timeId = setTimeout(() => {
      handleClose();
    }, duration);
    return () => {
      clearTimeout(timeId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const getIconByType = () => {
    switch (type) {
      case "success":
        return (
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            // dataTestid="SuccessOutlinedIcon"
          >
            <path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"></path>
          </svg>
        );
      case "warning":
        return (
          <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1vooibu-MuiSvgIcon-root"
            focusabariaHiddenle="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            // dataTestid="ReportProblemOutlinedIcon"
          >
            <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"></path>
          </svg>
        );
      case "info":
        return (
          <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1vooibu-MuiSvgIcon-root"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            // dataTestid="InfoOutlinedIcon"
          >
            <path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z"></path>
          </svg>
        );
      case "error":
        return (
          <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1vooibu-MuiSvgIcon-root"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            // dataTestid="ErrorOutlineIcon"
          >
            <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
          </svg>
        );
      default:
        return null;
    }
  };

  return (
    <div className="custom-alert-wrapper">
      {open && (
        <div className="custom-alert-message" role="presentation">
          <div className={`custom-alert-${type}`} role="alert" direction="up">
            <div className="custom-alert-icon">{getIconByType()}</div>
            <div className="custom-alert-text">
              <FormattedMessage id={idmessage} />
            </div>
            <div className="custom-alert-action">
              <button
                className="custom-alert-button"
                tabIndex="0"
                type="button"
                aria-label="Close"
                title="Close"
                fdprocessedid="4mht6"
                onClick={handleClose}
              >
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  // dataTestid="CloseIcon"
                >
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </svg>
                <span className="MuiTouchRipple-root"></span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};