import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Select, MenuItem, LinearProgress } from "@material-ui/core";
import CustomTable, {
  convertToTextField,
} from "../../../components/Table/Table";
import { Link } from "react-router-dom";
import InfoPrimaryIcon from "../../../assets/icons/baseline-info-24px.svg";
import "./InventoryHome.scss";
import FeedbackAlert from "../../../components/FeedbackAlert/FeedbackAlert";
import InternetErrorComponent from "../../../components/Errors/InternetErrorComponent";
import {
  getAllProductInventoryLastMovement,
  getAllProductInventoryForTransfer,
  createMassiveProductInventoryMovement,
  createMassiveTransferOfProducts,
  createMassiveProductInventoryAdd,
  createInventoryTemplate,
  getTemplatesTransfer,
  getTemplatesForcedUpdate,
  getTemplateById,
} from "../../../services/utilsProductInventory";

import { getAllSalePoint } from "../../../services/utilsSalePoint";
import { convertUTCtoLocalTimeString } from "../../../components/timezonefuncions";
import { movePerishableProducts } from "../../../services/utilsTags";

import TextField from "@mui/material/TextField";
import SearchBar from "../../../components/searchBar/searchBar";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
// import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableChartIcon from "@mui/icons-material/TableChartOutlined";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import SetMealIcon from "@mui/icons-material/SetMeal";
import InventoryIcon from "@mui/icons-material/Inventory";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { Dialog } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";

const InventoryHome = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [linealLoading, setLinealLoading] = useState(false);
  const [linealLoadingError, setLinealLoadingError] = useState(false);
  const [salePoints, setSalePoints] = useState([]);
  const [modeStatus, setModeStatus] = useState(4);
  const [editingInventory, setEditingInventory] = useState(false);
  const [selectedSalePoint, setSelectedSalePoint] = useState(null);
  const [rawProductsData, setRawProductsData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [destinationSelectedSalePoint, setDestinationSelectedSalePoint] =
    useState(null);
  const [prevModeStatus, setPrevModeStatus] = useState(modeStatus);
  const [prevSelectedSalePoint, setPrevSelectedSalePoint] =
    useState(selectedSalePoint);
  const [openFeedbackAlert, setOpenFeedbackAlert] = useState(false);
  const [movingPerishable, setMovingPerishable] = useState(false);
  const productsUpdate = useRef([]);

  // Dialog variables
  const [openCreate, setOpenCreate] = useState(false);
  const [openLoad, setOpenLoad] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [messageId, setMessageId] = useState({id: "", type: "warning"});

  useEffect(() => {
    fetchSalePoints();
  }, []);

  useEffect(() => {
    if (selectedSalePoint) {
      if (modeStatus < 3) {
        if (
          selectedSalePoint !== prevSelectedSalePoint ||
          (modeStatus !== prevModeStatus && prevModeStatus === 3)
        ) {
          fetchProductCurrentInventory(selectedSalePoint);
          setPrevSelectedSalePoint(selectedSalePoint);
        }
      } else if (modeStatus === 3) {
        setTableRows([]);
        setDestinationSelectedSalePoint(null);
      }
    }
    if (modeStatus !== prevModeStatus) {
      setPrevModeStatus(modeStatus);
    }
    // setSearchQuery("");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeStatus, selectedSalePoint]);

  useEffect(() => {
    if (selectedSalePoint && destinationSelectedSalePoint) {
      fetchProductCurrentInventoryTrasnfer(
        selectedSalePoint,
        destinationSelectedSalePoint
      );
    }
    // setSearchQuery("");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSalePoint, destinationSelectedSalePoint]);

  useEffect(() => {
    if (rawProductsData && !editingInventory) {
      let dataReady = rawProductsData.map((product) => {
        let rowData;
        switch (modeStatus) {
          case 0:
            rowData = createRowForMode0(
              product.image,
              product.name,
              product.sku,
              product.current_cuantity,
              product.last_date,
              product.fk_product
            );
            break;
          case 1:
            rowData = createRowForMode1(
              product.image,
              product.name,
              product.sku,
              product.current_cuantity,
              product.last_date,
              product.fk_product
            );
            break;
          case 2:
            rowData = createRowForMode2(
              product.image,
              product.name,
              product.sku,
              product.current_cuantity,
              product.last_date,
              product.current_cuantity,
              product.fk_product
            );
            break;
          case 3:
            rowData = createRowForMode3(
              product.image,
              product.name,
              product.source_quantity,
              product.destination_quantity,
              product.fk_product
            );
            break;
          default:
            rowData = null;
        }
        return rowData;
      });
      setTableRows(dataReady);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeStatus, rawProductsData, editingInventory]);

  const filterData = (rows) => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = rows.filter(
      (row) =>
        row.name.toLowerCase().includes(lowercasedQuery) ||
        (row.sku && row.sku.toLowerCase().includes(lowercasedQuery))
    );
    return filtered;
  };

  const handleSelectedSalePoint = async (e) => {
    const value = e.target.value;
    setSelectedSalePoint(value);
    await localStorage.setItem("fk_sale_point", value);

    if (modeStatus !== undefined) {
      setModeStatus(0);
    }

    setRawProductsData([]);
    setTableRows([]);
  };

  const handleSubmitNewInventory = (e) => { 
    e.preventDefault();
    setLinealLoading(true);
    let movementType = 0;
    if (modeStatus === 2) {
      movementType = 1;
    }
    let fk_supervisor = JSON.parse(localStorage.getItem("profile"))["profile"][
      "user"
    ]["supervisor"]["id"];

    if (
      (modeStatus === 1 || modeStatus === 2) &&
      checkNotNegativeProducts(productsUpdate.current)
    ) {
      setMessageId({id: "negativeProductsLbl", type: "warning"});
      setOpenFeedback(true);
      return;
    }

    switch (modeStatus) {
      case 1: {
        const jsonBody = {
          fk_sale_point: selectedSalePoint,
          movement_type: movementType,
          products: productsUpdate.current,
          fk_supervisor: fk_supervisor,
        };
        createMassiveProductInventoryMovement(JSON.stringify(jsonBody)).then(
          (data) => {
            fetchProductCurrentInventory(selectedSalePoint);
            setOpenFeedbackAlert(true);
            // setModeStatus(0);
            setEditingInventory(false);
            productsUpdate.current = [];
          }
        );
        break;
      }
      case 2: {
        const jsonBody = {
          fk_sale_point: selectedSalePoint,
          movement_type: movementType,
          products: productsUpdate.current,
          fk_supervisor: fk_supervisor,
        };
        createMassiveProductInventoryAdd(JSON.stringify(jsonBody)).then(
          (data) => {
            fetchProductCurrentInventory(selectedSalePoint);
            setOpenFeedbackAlert(true);
            // setModeStatus(0);
            setEditingInventory(false);
            productsUpdate.current = [];
          }
        );
        break;
      }
      case 3: {
        const productsTransfer = productsUpdate.current.map((obj) => {
          const { fk_product, new_quantity } = obj;
          const int_quantity = +new_quantity;

          return { fk_product: fk_product, quantity: int_quantity };
        });
        const jsonBody = {
          fk_sale_point_source: selectedSalePoint,
          fk_sale_point_destination: destinationSelectedSalePoint,
          transaction_type: 0,
          products: productsTransfer,
          fk_supervisor: fk_supervisor,
        };
        createMassiveTransferOfProducts(JSON.stringify(jsonBody)).then(
          (data) => {
            // fetchProductCurrentInventory(selectedSalePoint);
            fetchProductCurrentInventoryTrasnfer(
              selectedSalePoint,
              destinationSelectedSalePoint
            );
            setOpenFeedbackAlert(true);
            // setModeStatus(0);
            setEditingInventory(false);
            productsUpdate.current = [];
          }
        );
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleModePerishableProducts = async () => {
    setMovingPerishable(true);
    let fkSupervisor = JSON.parse(localStorage.getItem("profile")).profile.user
      .supervisor.id;
    let jsonBody = {
      fk_origin_sale_point: selectedSalePoint,
      fk_destiny_sale_point: destinationSelectedSalePoint,
      fk_supervisor: fkSupervisor,
    };
    let move = await movePerishableProducts(JSON.stringify(jsonBody));
    if (move.moved_products) {
      setMovingPerishable(false);
    }
  };

  const handleChangeCurrentInventoryMode1 = (event, rows, rowId) => {
    let eventValue = parseInt(event.target.value === '' ? 0 : event.target.value);
    let jsonRow = { fk_product: rowId, new_quantity: eventValue };

    if (eventValue >= 0) {
      let newRows = rows.map((row) => {
        if (row.currentIntentory[3] === rowId) {
          row.currentIntentory[0] = event.target.value;
        }
        return row;
      });

      if (!editingInventory) {
        setEditingInventory(true);
      }

      setTableRows(newRows);
    } else {
      setMessageId({id: "negativeProductsLbl", type: "warning"});
      setOpenFeedback(true);
    }

    let existingProductIndex = productsUpdate.current.findIndex(
      (product) => product.fk_product === rowId
    );

    if (existingProductIndex !== -1) {
      productsUpdate.current[existingProductIndex] = {
        ...productsUpdate.current[existingProductIndex],
        ...jsonRow,
      };
    } else {
      // Item doesn't exist, add it to updatedProducts
      productsUpdate.current.push(jsonRow);
    }
  };

  const handleAddCurrentInventory = (event, rows, rowId) => {
    let eventValue = parseFloat(event.target.value === '' ? 0 : event.target.value);

    if (eventValue >= 0) {
      let newRows = rows.map((row) => {
        if (row.addInventory[3] === rowId) {
          let currentQuantity = row.currentInventory;

          row.addInventory[0] = event.target.value;
          row.newCurrentInventory = eventValue + currentQuantity;
        }
        return row;
      });

      let jsonRow = { fk_product: rowId, new_quantity: eventValue };

      if (!editingInventory) {
        setEditingInventory(true);
      }

      setTableRows(newRows);

      let existingProductIndex = productsUpdate.current.findIndex(
        (product) => product.fk_product === rowId
      );

      if (existingProductIndex !== -1) {
        productsUpdate.current[existingProductIndex] = {
          ...productsUpdate.current[existingProductIndex],
          ...jsonRow,
        };
      } else {
        // Item doesn't exist, add it to updatedProducts
        productsUpdate.current.push(jsonRow);
      }
    } else {
      setMessageId({id: "negativeProductsLbl", type: "warning"});
      setOpenFeedback(true);
    }
  };

  const handleCancelEdit = (e) => {
    e.preventDefault();
    setEditingInventory(false);
    productsUpdate.current = [];
  };

  const handleTextSearch = (event) => {
    setSearchQuery(event);
  };

  const handleTransferQuantity = (event, rows, rowId) => {
    let eventValue = parseInt(event.target.value === '' ? 0 : event.target.value);
    let newSource, newDestination;
    let jsonRow = { fk_product: rowId, new_quantity: eventValue };

    if (eventValue >= 0) {
      let newRows = rows.map((row) => {
        if (row.transferQuantity[3] === rowId) {
          let sourceQuantity = parseInt(row["sourceString"].split(" ")[0]);
          let destinationQuantity = parseInt(
            row["destinationString"].split(" ")[0]
          );
          if (sourceQuantity > 0) {
            if (eventValue > sourceQuantity) {
              eventValue = sourceQuantity;
              setMessageId({id: "notEnoughProductsLbl", type: "warning"});
              setOpenFeedback(true);
            }

            newSource = sourceQuantity - eventValue + " UND";
            newDestination = destinationQuantity + eventValue + " UND";

            row.transferQuantity[0] = event.target.value;
            row.newSourceQuantity = newSource;
            row.newDestinationQuanity = newDestination;
            if (!editingInventory) {
              setEditingInventory(true);
            }
          }
        }
        return row;
      });
      setTableRows(newRows);
    } else {
      setMessageId({id: "negativeProductsLbl", type: "warning"});
      setOpenFeedback(true);
    }

    let existingProductIndex = productsUpdate.current.findIndex(
      (product) => product.fk_product === rowId
    );

    if (existingProductIndex !== -1) {
      productsUpdate.current[existingProductIndex] = {
        ...productsUpdate.current[existingProductIndex],
        ...jsonRow,
      };
    } else {
      // Item doesn't exist, add it to updatedProducts
      productsUpdate.current.push(jsonRow);
    }
  };

  // Function to fetch sale points
  const fetchSalePoints = async () => {
    try {
      // Set loading state
      let fkOrganization = localStorage.getItem("fk_organization");
      setLinealLoading(true);

      // Call API to get sale points
      const response = await getAllSalePoint(fkOrganization);
      // Extract sale points from the response
      const formated = response.sale_points.map((point) => ({
        label: point.name,
        value: point.id,
      }));

      // Set sale points in the state
      setSalePoints(formated);

      // Clear loading state
      setLinealLoading(false);
    } catch (error) {
      // Handle error
      console.error("Error fetching sale points:", error);

      // Set error state
      setLinealLoading(false);
    }
  };

  // Function to fetch current inventory for a selected sale point
  const fetchProductCurrentInventory = async (salePointId) => {
    try {
      // Set loading state
      setLinealLoading(true);

      // Call API to get product inventory for the selected sale point
      const response = await getAllProductInventoryLastMovement(salePointId);

      // Set raw products data in the state
      setRawProductsData(response.products);

      // Extract relevant data from the response
      const newTableRows = response.products.map((product) =>
        createRowForMode0(
          product.image,
          product.name,
          product.sku,
          product.current_cuantity,
          product.last_date,
          product.fk_product
        )
      );

      // Set table rows in the state
      setTableRows(newTableRows);

      // Clear loading state
      setLinealLoading(false);
    } catch (error) {
      // Handle error
      console.error("Error fetching product current inventory:", error);
      // Set error state
      setLinealLoadingError(true);
    }
  };

  // Function to fetch product current inventory for transfer
  const fetchProductCurrentInventoryTrasnfer = async (
    sourceSalePointId,
    destinationSalePointId
  ) => {
    try {
      // Set loading state
      setLinealLoading(true);

      // Call API to get product inventory for the selected sale point
      const response = await getAllProductInventoryForTransfer(
        sourceSalePointId,
        destinationSalePointId
      );

      // Extract relevant data from the response
      const newRawProductsData = response.products.map((product) =>
        createRowForMode3(
          product.image,
          product.name,
          product.source_quantity,
          product.destination_quantity,
          product.fk_product
        )
      );

      // Set raw products data in the state
      setRawProductsData(response.products);

      // Set table rows in the state
      setTableRows(newRawProductsData);

      // Clear loading state
      setLinealLoading(false);
    } catch (error) {
      // Handle error
      console.error(
        "Error fetching product current inventory for transfer:",
        error
      );
      // Set error state
      setLinealLoadingError(true);
    }
  };

  // //-----------------------------------------------
  // // TABLE MANGEMENT           --------------------
  // //-----------------------------------------------
  const renderModeTable = () => {
    let inventoryTableHeaders;
    switch (modeStatus) {
      case 0:
        inventoryTableHeaders = [
          "emptyHeader",
          "nameHeader",
          "skuHeader",
          "dateHeader",
          "currentQuantityHeader",

          "actionsHeader",
        ];

        return (
          <CustomTable
            stickyHeader
            filtered={filterData}
            headers={inventoryTableHeaders}
            rows={tableRows}
            rowTypes={[[0, "image"]]}
            pagination={""}
          />
        );

      case 1:
        inventoryTableHeaders = [
          "emptyHeader",
          "nameHeader",
          "skuHeader",
          "dateHeader",
          "currentQuantityHeader",
          "actionsHeader",
        ];

        return (
          <CustomTable
            stickyHeader
            filtered={filterData}
            headers={inventoryTableHeaders}
            rows={tableRows}
            rowTypes={[
              [0, "image"],
              [4, "textfield-number"],
            ]}
            pagination={""}
          />
        );

      case 2:
        inventoryTableHeaders = [
          "emptyHeader",
          "nameHeader",
          "skuHeader",
          "dateHeader",
          "currentQuantityHeader",
          "addHeader",
          "newQuantityHeader",
          "actionsHeader",
        ];

        return (
          <CustomTable
            stickyHeader
            filtered={filterData}
            headers={inventoryTableHeaders}
            rows={tableRows}
            rowTypes={[
              [0, "image"],
              [5, "textfield-number"],
            ]}
            pagination={""}
          />
        );
      case 3:
        inventoryTableHeaders = [
          "emptyHeader",
          "nameHeader",
          "currentSourceQuantityHeader",
          "currentDestinationQuantityHeader",
          "transferQauntityHeader",
          "newQuantitySourceHeader",
          "newQuantityDestinationHeader",
          "actionsHeader",
        ];

        return (
          <CustomTable
            stickyHeader
            filtered={filterData}
            headers={inventoryTableHeaders}
            rows={tableRows}
            rowTypes={[
              [0, "image"],
              [4, "textfield-number"],
            ]}
            pagination={""}
          />
        );
          
      default:
        return null;
      }
  };

  const createRowForMode0 = (img, name, sku, currentInventory, date, id) => {
    let currentInventoryString = currentInventory + " UND";
    const localTimeString = convertUTCtoLocalTimeString(date);
    return {
      img,
      name,
      sku,
      localTimeString,
      currentInventoryString,
      actions: (
        <>
          <div className="flex">
            <Link to={`/inventarios/${id}`} onClick={() => goProduct(id)}>
              <img
                src={InfoPrimaryIcon}
                alt="info"
                onClick={() => ""}
                className="table-icon"
              />
            </Link>
          </div>
        </>
      ),
    };
  };

  const createRowForMode1 = (img, name, sku, currentInventory, date, id) => {
    const localTimeString = convertUTCtoLocalTimeString(date);

    return {
      img,
      name,
      sku,
      localTimeString,
      currentIntentory: convertToTextField(
        currentInventory,
        "currentInventory",
        handleChangeCurrentInventoryMode1,
        id
      ),
      actions: (
        <>
          <div className="flex">
            <a
              href={`/inventarios/${id}`}
              onClick={(event) => {
                event.preventDefault();
                goProduct(id);
                // This will manually open the link in a new tab
                window.open(`/inventarios/${id}`, "_blank");
              }}
            >
              <img src={InfoPrimaryIcon} alt="info" className="table-icon" />
            </a>
          </div>
        </>
      ),
    };
  };

  const createRowForMode2 = (
    img,
    name,
    sku,
    currentInventory,
    date,
    newCurrentInventory,
    id
  ) => {
    const localTimeString = convertUTCtoLocalTimeString(date);

    return {
      img,
      name,
      sku,
      localTimeString,
      currentInventory,
      addInventory: convertToTextField(
        0,
        "addInventory",
        handleAddCurrentInventory,
        id
      ),
      newCurrentInventory,
      actions: (
        <>
          <div className="flex">
            <a
              href={`/inventarios/${id}`}
              onClick={(event) => {
                event.preventDefault();
                goProduct(id);
                // This will manually open the link in a new tab
                window.open(`/inventarios/${id}`, "_blank");
              }}
            >
              <img src={InfoPrimaryIcon} alt="info" className="table-icon" />
            </a>
          </div>
        </>
      ),
    };
  };

  const createRowForMode3 = (
    img,
    name,
    sourceQuantity,
    destinationQuantity,
    id
  ) => {
    let sourceString = sourceQuantity + " UND";
    let destinationString = destinationQuantity + " UND";
    return {
      img,
      name,
      sourceString,
      destinationString,
      transferQuantity: convertToTextField(
        0,
        "transferQuanitity",
        handleTransferQuantity,
        id,
        { max: sourceQuantity }
      ),
      newSourceQuantity: sourceString,
      newDestinationQuanity: destinationString,
      actions: (
        <>
          <div className="flex">
            <a
              href={`/inventarios/${id}`}
              onClick={(event) => {
                event.preventDefault();
                goProduct(id);
                // This will manually open the link in a new tab
                window.open(`/inventarios/${id}`, "_blank");
              }}
            >
              <img src={InfoPrimaryIcon} alt="info" className="table-icon" />
            </a>
          </div>
        </>
      ),
    };
  };

  // //-----------------------------------------------
  // // Aux funtions              --------------------
  // //-----------------------------------------------
  let goProduct = (id) => {
    localStorage.setItem("fk_product", id);
    localStorage.setItem("fk_sale_point", selectedSalePoint);
  };

  const handleMenuActions = async (type) => {
    switch (type) {
      case "templates-create":
        setOpenCreate(true);
        break;
      case "templates-load":
        setOpenLoad(true);
        break;
      case "perishable":
        setLinealLoading(true);
        await handleModePerishableProducts();
        setMessageId({id: "traslatePerishableSuccess", type: "success"});
        setOpenFeedback(true);
        setLinealLoading(false);
        break;
      default:
        break;
    }
  };

  const handleSubmitLoadTemplate = async (templateId, force = false) => {
    try {
      const data = await getTemplateById(templateId);
      switch (modeStatus) {
        case 1:
          handleLoadTemplateForcedUpdate(
            data.inventory_template.product_inventory_templates
          );
          return { success: true };
        case 3:
          const result = handleLoadTemplateTransfer(
            data.inventory_template.product_inventory_templates,
            force
          );
          return result;
        default:
          return { success: false };
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoadTemplateForcedUpdate = (products) => {
    for (var product of products) {
      let jsonRow = {
        fk_product: product.fk_product,
        new_quantity: product.quantity,
      };
      let newRows = tableRows.map((row) => {
        if (row.currentIntentory[3] === product.fk_product) {
          row.currentIntentory[0] = product.quantity;
        }
        return row;
      });

      if (!editingInventory) {
        setEditingInventory(true);
      }

      setTableRows(newRows);

      let existingProductIndex = productsUpdate.current.findIndex(
        (producti) => producti.fk_product === product.fk_product
      );

      if (existingProductIndex !== -1) {
        productsUpdate.current[existingProductIndex] = {
          ...productsUpdate.current[existingProductIndex],
          ...jsonRow,
        };
      } else {
        // Item doesn't exist, add it to updatedProducts
        productsUpdate.current.push(jsonRow);
      }
    }
  };

  const handleLoadTemplateTransfer = (products, force = false) => {
    let tempRows = JSON.parse(JSON.stringify(tableRows));
    let tempProductsUpdate = JSON.parse(JSON.stringify(productsUpdate.current));
    let errors = [];
    for (var product of products) {
      let quantity = parseInt(product.quantity);
      let newSource, newDestination;
      let jsonRow = { fk_product: product.fk_product, new_quantity: quantity };

      if (quantity >= 0) {
        tempRows.map((row) => {
          if (row.transferQuantity[3] === product.fk_product) {
            let sourceQuantity = parseInt(row["sourceString"].split(" ")[0]);
            let destinationQuantity = parseInt(
              row["destinationString"].split(" ")[0]
            );
            if (sourceQuantity > 0 && sourceQuantity >= quantity) {
              newSource = sourceQuantity - quantity + " UND";
              newDestination = destinationQuantity + quantity + " UND";

              row.transferQuantity[0] = parseInt(quantity);
              row.newSourceQuantity = newSource;
              row.newDestinationQuanity = newDestination;
              // if (!editingInventory) {
              //   setEditingInventory(true);
              // }
            } else {
              if (force) {
                newSource = sourceQuantity - sourceQuantity + " UND";
                newDestination = destinationQuantity + sourceQuantity + " UND";

                row.transferQuantity[0] = parseInt(sourceQuantity);
                row.newSourceQuantity = newSource;
                row.newDestinationQuanity = newDestination;
              } else {
                errors.push({
                  ...jsonRow,
                  quantity,
                  sourceQuantity,
                  name: row["name"],
                });
              }
            }
          }
          return row;
        });
        // setTableRows(newRows);
      }

      let existingProductIndex = tempProductsUpdate.findIndex(
        (producti) => producti.fk_product === product.fk_product
      );

      if (existingProductIndex !== -1) {
        tempProductsUpdate[existingProductIndex] = {
          ...tempProductsUpdate[existingProductIndex],
          ...jsonRow,
        };
      } else {
        // Item doesn't exist, add it to updatedProducts
        tempProductsUpdate.push(jsonRow);
      }
    }

    if (errors.length > 0) {
      return { success: false, errors: errors };
    } else {
      if (!editingInventory) {
        setEditingInventory(true);
      }
      let newRows = tableRows.map((row) => {
        let temp = tempRows.find(
          (ele) => ele.transferQuantity[3] === row.transferQuantity[3]
        );
        row.transferQuantity[0] = temp.transferQuantity[0];
        row.newSourceQuantity = temp.newSourceQuantity;
        row.newDestinationQuanity = temp.newDestinationQuanity;
        return row;
      });
      setTableRows(newRows);
      productsUpdate.current = tempProductsUpdate;
      return { success: true };
    }
  };

  const checkNotNegativeProducts = (products) => {
    return products.some((product) => product.new_quantity < 0);
  };

  return (
    <main className="section-body inventory-container">
      {/* Loading indicators */}
      <div className="headers-wrapper">
        {/* Selectors */}
        <div className="row">
          <div className="col-3 mb-4">
            <label className="form-label" htmlFor="salePointInput">
              <FormattedMessage id="franchiseLbl" />
            </label>
            <Select
              required
              value={selectedSalePoint ? selectedSalePoint : ""}
              name="fk_sale_point"
              onChange={handleSelectedSalePoint}
              className="worker-form-select"
              id="salePointInput"
              disabled={editingInventory}
            >
              {salePoints.map((salePoint, i) => (
                <MenuItem key={i} value={salePoint.value}>
                  {salePoint.label}
                </MenuItem>
              ))}
            </Select>
          </div>
          {/* Mode Selector */}
          <div className="col-3 mb-4">
            <label className="form-label" htmlFor="modeInput">
              <FormattedMessage id="modelbl" />
            </label>
            <Select
              required
              value={modeStatus}
              name="mode"
              onChange={(e) => setModeStatus(e.target.value)}
              className="worker-form-select"
              id="modeInput"
              disabled={editingInventory || salePoints.lenght === 0}
            >
              <MenuItem key={4} value={4}>
                Seleccionar modo
              </MenuItem>
              <MenuItem key={0} value={0}>
                <FormattedMessage id="modeNormalViewInventorylbl" />
              </MenuItem>
              <MenuItem key={1} value={1}>
                <FormattedMessage id="modeForcedInventorylbl" />
              </MenuItem>
              <MenuItem key={2} value={2}>
                <FormattedMessage id="modeAddInventorylbl" />
              </MenuItem>
              <MenuItem key={3} value={3}>
                <FormattedMessage id="modeTransferlbl" />
              </MenuItem>
            </Select>
          </div>
          {/* Destination Sale Point Selector */}
          {modeStatus === 3 && (
            <div className="col-3 mb-4">
              <label className="form-label" htmlFor="destSalePointInput">
                <FormattedMessage id="destiantionSalePointLbl" />
              </label>
              <Select
                required
                value={
                  destinationSelectedSalePoint
                    ? destinationSelectedSalePoint
                    : ""
                }
                name="destinationSalePoint"
                onChange={(e) =>
                  setDestinationSelectedSalePoint(e.target.value)
                }
                className="worker-form-select"
                id="destSalePointInput"
                disabled={editingInventory}
              >
                {salePoints
                  .filter((salePoint) => salePoint.value !== selectedSalePoint)
                  .map((salePoint, i) => (
                    <MenuItem key={i} value={salePoint.value}>
                      {salePoint.label}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          )}

          <div className="col mb-2">
            <form className="h-100">
              <div className="row justify-content-end">
                <div className="col-12 d-flex justify-content-end align-items-end flex-wrap">
                  {editingInventory && (
                    <>
                      <button
                        className="btn btn-primary me-2"
                        type="submit"
                        onClick={handleSubmitNewInventory}
                      >
                        <FormattedMessage id={modeStatus === 3 ? "transferbutton" : "savebutton"} />
                      </button>
                      <button
                        className="btn btn-outline-primary me-2"
                        onClick={handleCancelEdit}
                      >
                        <FormattedMessage id="cancelLbl" />
                      </button>
                    </>
                  )}
                  {(modeStatus === 3 || modeStatus === 1) && !linealLoading && (
                    <DropdownMenu
                      modeStatus={modeStatus}
                      destination={destinationSelectedSalePoint}
                      editing={editingInventory}
                      onAction={handleMenuActions}
                      disabled={movingPerishable}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        {selectedSalePoint && modeStatus !== 4 && !linealLoading && (
          <section>
            <SearchBar
              input={searchQuery}
              onChange={handleTextSearch}
              nombre={modeStatus !== 3 ? "Producto / SKU" : "Producto"}
            />
          </section>
        )}
        {linealLoading && !linealLoadingError && <LinearProgress />}
        {linealLoadingError && <InternetErrorComponent />}
      </div>

      {/* Table */}
      <div className="table-wrapper">
        {modeStatus !== 3 &&
          selectedSalePoint &&
          tableRows &&
          !linealLoading &&
          renderModeTable()}
        {modeStatus === 3 &&
          tableRows &&
          destinationSelectedSalePoint &&
          selectedSalePoint &&
          !linealLoading &&
          renderModeTable()}
      </div>

      {/* Feedback Alert */}
      {openFeedbackAlert && (
        <FeedbackAlert
          type="success"
          message={<FormattedMessage id="inventoryCreated" />}
          onClose={() => setOpenFeedbackAlert(false)}
        />
      )}

      {/* Dialogs */}
      <CreateTemplate
        open={openCreate}
        onClose={setOpenCreate}
        modeStatus={modeStatus}
        salePoint={selectedSalePoint}
        destination={destinationSelectedSalePoint}
        products={productsUpdate.current}
      />
      <LoadTemplate
        open={openLoad}
        modeStatus={modeStatus}
        onClose={setOpenLoad}
        salePoint={selectedSalePoint}
        destination={destinationSelectedSalePoint}
        handler={handleSubmitLoadTemplate}
      />

      <Feedback
        type={messageId.type}
        idmessage={messageId.id}
        open={openFeedback}
        setOpen={setOpenFeedback}
      />
    </main>
  );
};

export default InventoryHome;

const DropdownMenu = ({
  modeStatus,
  destination,
  editing,
  onAction,
  disabled = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (type) => {
    onAction(type);
    handleClose();
  };

  return (
    <div>
      <Link to={`/templates`} >
        <IconButton
          aria-label="templates"
          id="templates_id"
        >
          <TableChartIcon />
        </IconButton>    
      </Link>
      <IconButton
        aria-label="more"
        id="long-button-menu"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {modeStatus === 3 && !editing && destination && (
          <MenuItem onClick={() => handleAction("perishable")}>
            <ListItemIcon>
              <SetMealIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Mover perecederos</ListItemText>
          </MenuItem>
        )}
        {(modeStatus === 3 || modeStatus === 1) && (
          <div>
            <MenuItem onClick={() => handleAction("templates-create")}>
              <ListItemIcon>
                <InventoryIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Crear plantilla</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleAction("templates-load")}>
              <ListItemIcon>
                <SystemUpdateAltIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Cargar plantilla</ListItemText>
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  );
};

const CreateTemplate = ({
  open,
  onClose,
  modeStatus,
  salePoint,
  destination,
  products,
}) => {
  const [seeSuccess, setSeeSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const { name, description } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleCancelButton = (event) => {
    event.preventDefault();
    onClose(false);
  };

  const handleSuccess = (event) => {
    event.preventDefault();
    onClose(false);
    setSeeSuccess(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    try {
      if (modeStatus === 1) {
        let jsonBody = {
          name: name,
          description: description,
          type: 0,
          fk_sale_point_origin: salePoint,
          fk_sale_point_destiny: salePoint,
          product_inventory_templates: products,
        };
        createInventoryTemplate(JSON.stringify(jsonBody)).then(async (data) => {
          setSubmitted(false);
          setSeeSuccess(true);
        });
      }
      if (modeStatus === 3) {
        let jsonBody = {
          name: name,
          description: description,
          type: 1,
          fk_sale_point_origin: salePoint,
          fk_sale_point_destiny: destination,
          product_inventory_templates: products,
        };
        createInventoryTemplate(JSON.stringify(jsonBody)).then(async (data) => {
          setSubmitted(false);
          setSeeSuccess(true);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} maxWidth={"md"} fullWidth>
      {!seeSuccess && (
        <form onSubmit={handleSubmit} className="section-dialog">
          <article>
            <h2 className="section-title">
              <FormattedMessage id="createTemplateTitle" />
            </h2>
          </article>
          <article className="container">
            <div className="field-dialog mb-4">
              <label className="form-label" htmlFor="template-name">
                <FormattedMessage id="firstNameLbl" />
              </label>
              <TextField
                name="name"
                id="template-name"
                variant="standard"
                onChange={(event) => onChange(event)}
                required
              />
            </div>
            <div className="field-dialog mb-4">
              <label className="form-label" htmlFor="template-description">
                <FormattedMessage id="descriptionLbl" />
              </label>
              <TextField
                name="description"
                id="template-description"
                variant="standard"
                onChange={(event) => onChange(event)}
                required
              />
            </div>
          </article>

          <article className="buttons-inventory">
            <button
              className="btn btn-outline-primary m-2"
              onClick={handleCancelButton}
              disabled={submitted}
            >
              <FormattedMessage id="cancelLbl" />
            </button>

            <button className="btn btn-primary m-2" type="submit">
              {!submitted && <FormattedMessage id={"acceptLbl"} />}
              {submitted && <span>LOADING...</span>}
            </button>
          </article>
        </form>
      )}
      {seeSuccess && (
        <>
          <div>
            <article className="section-dialog flex center-items size-dialog">
              <div className="center-text">
                <CheckCircleIcon color="success" />
              </div>
              <div className="success-inventory">
                <h2 className="section-title">
                  ¡Plantilla creada exitosamente!
                </h2>
                <p>Ahora podrás utilizarla siempre que la necesites.</p>
              </div>
            </article>
            <form action="" className="items-right">
              <button className="btn btn-primary m-2" onClick={handleSuccess}>
                ACEPTAR
              </button>
            </form>
          </div>
        </>
      )}
    </Dialog>
  );
};

const LoadTemplate = ({
  open,
  onClose,
  modeStatus,
  salePoint,
  destination,
  handler,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [seeSuccess, setSeeSuccess] = useState(false);
  const [seeErrors, setSeeErrors] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(-1);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (modeStatus && salePoint) {
      getTemplates();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, modeStatus, salePoint, destination]);

  const getTemplates = async () => {
    try {
      let template;
      if (modeStatus === 1) {
        template = await getTemplatesForcedUpdate(salePoint);
      } else if (modeStatus === 3) {
        template = await getTemplatesTransfer(salePoint, destination);
      }

      if (template) {
        setTemplates(template.inventory_templates);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelButton = (event) => {
    event.preventDefault();
    setTemplates([]);
    setErrors([]);
    onClose(false);
    setSeeErrors(false);
    setSeeSuccess(false);
  };

  const handleSuccess = (event) => {
    event.preventDefault();
    setTemplates([]);
    onClose(false);
    setSeeSuccess(false);
  };

  const handleErrors = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    try {
      // const response = await handler(selectedTemplate, true);
      setSubmitted(false);
      setTemplates([]);
      setErrors([]);
      setSelectedTemplate(-1);
      setSeeSuccess(false);
      setSeeErrors(false);
      onClose(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    try {
      const response = await handler(selectedTemplate);
      setSubmitted(false);
      setSeeSuccess(response.success);
      setTemplates([]);
      if (response.errors) {
        setErrors(response.errors);
        setSeeErrors(true);
      } else {
        setSelectedTemplate(-1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} maxWidth={"md"} fullWidth>
      {!seeSuccess && !seeErrors && (
        <form onSubmit={handleSubmit} className="section-dialog">
          <article>
            <h2 className="section-title">
              <FormattedMessage id="loadTemplateTitle" />
            </h2>
          </article>
          <article className="container">
            <div className="field-dialog mb-4">
              <label className="form-label" htmlFor="modeInput">
                <FormattedMessage id="templatelbl" />
              </label>
              <Select
                value={selectedTemplate}
                name="fk_sale_template"
                onChange={(e) => setSelectedTemplate(e.target.value)}
                className="worker-form-select"
                id="templateInput"
              >
                <MenuItem key={-1} value={-1}>
                  Seleccionar plantilla
                </MenuItem>
                {templates &&
                  templates.length > 0 &&
                  templates.map((template, i) => (
                    <MenuItem key={i} value={template.id}>
                      {template.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            {templates && templates.length > 0 && selectedTemplate !== -1 && (
              <div>
                <div>
                  Nombre:{" "}
                  {templates.find((obj) => obj.id === selectedTemplate).name}
                </div>
                <div>
                  Descripción:{" "}
                  {
                    templates.find((obj) => obj.id === selectedTemplate)
                      .description
                  }
                </div>
              </div>
            )}
          </article>

          <article className="buttons-inventory">
            <button
              className="btn btn-outline-primary m-2"
              onClick={handleCancelButton}
              disabled={submitted}
            >
              <FormattedMessage id="cancelLbl" />
            </button>

            <button className="btn btn-primary m-2" type="submit">
              {!submitted && <FormattedMessage id={"acceptLbl"} />}
              {submitted && <span>LOADING...</span>}
            </button>
          </article>
        </form>
      )}
      {seeSuccess && (
        <>
          <div>
            <article className="section-dialog flex center-items size-dialog">
              <div className="center-text">
                <CheckCircleIcon color="success" />
              </div>
              <div className="success-inventory">
                <h2 className="section-title">
                  ¡Plantilla cargada exitosamente!
                </h2>
                <p>
                  Los datos han sido cargados satisfactoriamente desde la
                  plantilla seleccionada.
                </p>
              </div>
            </article>
            <form action="" className="items-right">
              <button className="btn btn-primary m-2" onClick={handleSuccess}>
                ACEPTAR
              </button>
            </form>
          </div>
        </>
      )}
      {seeErrors && (
        <>
          <div>
            <article className="section-dialog flex center-items size-dialog">
              <div className="center-text">
                <WarningIcon color="warning" />
              </div>
              <div className="success-inventory">
                <h2 className="section-title">
                  ¡Ocurrió un error al cargar la plantilla!
                </h2>
                <p>
                  Los productos siguientes no tienen la cantidad necesaria para
                  ser transferidos con la plantilla elegida:
                </p>
                <div>
                  {errors.map((err, index) => (
                    <div className="table-error-wrapper" key={index}>
                      {index === 0 && (
                        <>
                          <div className="span-3 title">Producto</div>
                          <div className="title">Cant. Origen</div>
                          <div className="title">Cant. Requerida</div>
                        </>
                      )}
                      <div className="span-3">{err.name}</div>
                      <div>{err.sourceQuantity}</div>
                      <div>{err.new_quantity}</div>
                    </div>
                  ))}
                </div>
                <p>
                  Si decide proseguir, los productos se ajustarán a la cantidad
                  máxima disponible. ¿Desea continuar con la carga de la
                  plantilla?
                </p>
              </div>
            </article>
            <form action="" className="items-right">
              <button
                className="btn btn-outline-primary m-2"
                onClick={handleCancelButton}
                disabled={submitted}
              >
                <FormattedMessage id="cancelLbl" />
              </button>
              <button className="btn btn-primary m-2" onClick={handleErrors}>
                ACEPTAR
              </button>
            </form>
          </div>
        </>
      )}
    </Dialog>
  );
};

const Feedback = ({
  idmessage,
  open,
  setOpen,
  type = "success",
  duration = 3000,
}) => {
  useEffect(() => {
    const timeId = setTimeout(() => {
      handleClose();
    }, duration);
    return () => {
      clearTimeout(timeId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const getIconByType = () => {
    switch (type) {
      case "success":
        return (
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
            focusable="false"
            ariaHidden="true"
            viewBox="0 0 24 24"
            dataTestid="SuccessOutlinedIcon"
          >
            <path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"></path>
          </svg>
        );
      case "warning":
        return (
          <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1vooibu-MuiSvgIcon-root"
            focusable="false"
            ariaHidden="true"
            viewBox="0 0 24 24"
            dataTestid="ReportProblemOutlinedIcon"
          >
            <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"></path>
          </svg>
        );
      case "info":
        return (
          <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1vooibu-MuiSvgIcon-root"
            focusable="false"
            ariaHidden="true"
            viewBox="0 0 24 24"
            dataTestid="InfoOutlinedIcon"
          >
            <path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z"></path>
          </svg>
        );
      case "error":
        return (
          <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1vooibu-MuiSvgIcon-root"
            focusable="false"
            ariaHidden="true"
            viewBox="0 0 24 24"
            dataTestid="ErrorOutlineIcon"
          >
            <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
          </svg>
        );
      default:
        return null;
    }
  };

  return (
    <div className="custom-alert-wrapper">
      {open && (
        <div className="custom-alert-message" role="presentation">
          <div className={`custom-alert-${type}`} role="alert" direction="up">
            <div className="custom-alert-icon">{getIconByType()}</div>
            <div className="custom-alert-text">
              <FormattedMessage id={idmessage} />
            </div>
            <div className="custom-alert-action">
              <button
                className="custom-alert-button"
                tabindex="0"
                type="button"
                ariaLabel="Close"
                title="Close"
                fdprocessedid="4mht6"
                onClick={handleClose}
              >
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall"
                  focusable="false"
                  ariaHidden="true"
                  viewBox="0 0 24 24"
                  dataTestid="CloseIcon"
                >
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </svg>
                <span className="MuiTouchRipple-root"></span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
