import WalletIcon from "@mui/icons-material/Wallet";
import PendingIcon from "@mui/icons-material/Pending";
import UndoIcon from "@mui/icons-material/Undo";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { convertUTCtoLocalTimeStringMovements } from "../../timezonefuncions";
import { useState } from "react";
import { getStatusPayIn, cancelRecharge } from "../../../services/utilsWallet";
import { Modal, Box, Button } from '@mui/material';

const WalletPayIn = ({ movement }) => {
  const [movementObj, setMovementObj] = useState(movement);
  const [openDetail, setOpenDetail] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);



  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const formatMoney = (number) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };

  const statusToString = (status, method) => {
    switch (status) {
      case "SUCCESSFUL":
        return method === 'REFUND' ? "Exitoso" : "Exitosa";
      case "CANCELLED":
        return "Cancelada";
      case "FAILED":
        return "Fallida";
      case "IN_PROGRESS":
        return "En progreso";
      default:
        return "";
    }
  };

  const payInTypeToString = (type, transferTo = null) => {
    switch (type) {
      case "TRANSFER":
        return `Transferencia${transferTo ? ` a ${transferTo}` : ""}`;
      case "CREDIT_CARD":
        return "Tarjeta de crédito";
      case "CASHIER":
        return "Ventanilla";
      case "PAYROLL_DEDUCTION":
        return "Descuento por nómina";
      case "REFUND":
        return "Reembolso"
      case "PSE":
        return "PSE"
      default:
        return " ";
    }
  };

  const handleOpenDetailClick = () => {
    setOpenDetail((prevState) => !prevState);
  };

  const getStatusTransaction = async () => {
    setLoadingUpdate(true);
    console.log("getStatusTransaction", movementObj.idempotence_key);
    getStatusPayIn(movementObj.idempotence_key).then((res) => {
      setLoadingUpdate(false);
      setMovementObj(res.response);
      console.log("res", res.response);
    });
  };

  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxWidth: "500px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px",
  };

  const ResultModal = ({open, message, onClose}) => (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <h2 className="modal-title">{message}</h2>
          <div className="text-center modal-options">
            <Button onClick={onClose}>
              <a href="/billeteras">Cerrar</a>
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
    );



  const CustomModal = ({ open, message, onClose, onConfirm }) => (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <h2 className="modal-title">{message}</h2>
          <div className="text-center modal-options">
            <Button className="m-2" onClick={() => {
              onClose();
              onConfirm();
            }}>
              Sí
            </Button>
            <Button className="m-2" onClick={onClose}>
              No
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );






  return (
    <>
      <div className="row-wallet-information" onClick={handleOpenDetailClick}>
        <div className="row-wallet-information-rechage">
          <li className="time-text">
            {convertUTCtoLocalTimeStringMovements(movementObj.datetime)}
          </li>
          <div className="row-info-transaction">
            <div>
              {movementObj.status === "SUCCESSFUL" && (
                <div className="bachground-payin success">
                  <WalletIcon className="icon-recharge"></WalletIcon>
                </div>
              )}
              {movementObj.status === "REFUND" && (
                <div className="bachground-payin refund">
                  <UndoIcon className="icon-recharge"></UndoIcon>
                </div>
              )}
              {movementObj.status === "IN_PROGRESS" && (
                <div className="bachground-payin in_progress">
                  <PendingIcon className="icon-recharge"></PendingIcon>
                </div>
              )}
              {movementObj.status === "FAILED" && (
                <div className="bachground-payin failed">
                  <ClearIcon className="icon-recharge"></ClearIcon>
                </div>
              )}
              {movementObj.status === "CANCELLED" && (
                <div className="bachground-payin failed">
                  <ClearIcon className="icon-recharge"></ClearIcon>
                </div>
              )}
            </div>
            <div className="row-wallet-information-text">
              <li className="movement-title">Recarga</li>
              <li className="movement-sub-title">
                {payInTypeToString(movementObj.payment_method)}:{" "}
                {statusToString(movementObj.status, movementObj.payment_method)}
              </li>
            </div>
          </div>
        </div>
        <div className={`row-wallet-information-amount ${movementObj.status}`}>
          <li>{formatMoney(movementObj.amount / 100)}</li>
        </div>
      </div>
      {openDetail && (
        <div className={`info-container ${movementObj.status}`}>
          <div className="row title-info-detail">
            {movementObj.status === "SUCCESSFUL" && (
              <div className="title-info-detail">
                <div className="bachground-payin success">
                  <CheckIcon className="icon-recharge"></CheckIcon>
                </div>
                <h2>
                  {
                        movementObj.payment_method === 'REFUND' ? 'Reembolso Exitoso' : 'Recarga Exitosa'
                  }
                </h2>
              </div>
            )}
            {movementObj.status === "REFUND" && (
              <div className="title-info-detail">
                <div className="bachground-payin failed">
                  <UndoIcon className="icon-recharge"></UndoIcon>
                </div>
                <h2>T</h2>
              </div>
            )}
            {movementObj.status === "IN_PROGRESS" && (
              <div className="title-info-detail">
                <div className="bachground-payin in_progress">
                  <PendingIcon className="icon-recharge"></PendingIcon>
                </div>
                <h2>Recarga en progreso</h2>
              </div>
            )}
            {movementObj.status === "FAILED" && (
              <div className="title-info-detail">
                <div className="bachground-payin failed">
                  <ClearIcon className="icon-recharge"></ClearIcon>
                </div>
                <h2>Recarga fallida</h2>
              </div>
            )}
            {movementObj.status === "CANCELLED" && (
              <div className="title-info-detail">
                <div className="bachground-payin failed">
                  <UndoIcon className="icon-recharge"></UndoIcon>
                </div>
                <h2>Recarga cancelada</h2>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col">
              <h6 className={`info-detail-subtitle ${movementObj.status}`}>
                Id de la transacción
              </h6>
              <p>{movementObj.idempotence_key}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h6 className={`info-detail-subtitle ${movementObj.status}`}>
                Valor
              </h6>
              <p>{formatMoney(movementObj.amount / 100)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h6 className={`info-detail-subtitle ${movementObj.status}`}>
                Valor de la comisión
              </h6>
              <p>{formatMoney(movementObj.fee / 100)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h6 className={`info-detail-subtitle ${movementObj.status}`}>
                Medio de pago
              </h6>
              <p>{payInTypeToString(movementObj.payment_method, movementObj.payin_info?.transfer_to)}</p>
            </div>
          </div>
          {movementObj.payment_method === "CREDIT_CARD" && (
            <div className="row">
              <div className="col">
                <h6 className={`info-detail-subtitle ${movementObj.status}`}>
                  Banco
                </h6>
                <p>{movementObj.payin_info.data.transaction.data.banco}</p>
              </div>
            </div>
          )}
          {movementObj.status === "IN_PROGRESS" && (
            <>
              <div className="button-container">
                <button
                  className="btn btn-primary button-cancel m-2 in_progress"
                  onClick={getStatusTransaction}
                >
                  {!loadingUpdate && 'Actualizar'}
                  {loadingUpdate && (
                    <div className="d-flex justify-content-center spinner-container">
                      <div
                        className="spinner-border spinner-secondary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </button>
              </div>
            </>
          )}

          {movementObj.status === "SUCCESSFUL" && (
            <>
              <div className="button-container">
                <button
                  className="btn btn-danger button-cancel m-2"
                  onClick={() => setOpenConfirmationModal(true)}
                  disabled={loadingUpdate}
                >
                  {!loadingUpdate && 'Cancelar'}
                  {loadingUpdate && (
                    <div className="d-flex justify-content-center spinner-container">
                      <div
                        className="spinner-border spinner-secondary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </button>
              </div>

              {/* Modal to confirm*/}
              <CustomModal
                open={openConfirmationModal}
                message="¿Estás seguro de que deseas cancelar la recarga?"
                onClose={() => setOpenConfirmationModal(false)}
                onConfirm={async () => {
                  setLoadingUpdate(true);
                  const jsonBody = JSON.stringify({
                    idempotence_key: movementObj.idempotence_key,
                  });
                  try {
                    const response = await cancelRecharge(jsonBody);
                    if (response.error) {
                      setModalMessage("Fallida: Error al cancelar la transacción. Por favor haz clic en continuar y vuelve a intentarlo.");
                    } else {
                      setModalMessage("Recarga cancelada exitosamente.");
                    }
                  } catch (error) {
                    console.error("Error al cancelar la transacción, por favor haz clic en continuar y vuelve a intentarlo.");
                    setModalMessage("Fallida: Error al cancelar la transacción. Por favor haz clic en continuar y vuelve a intentarlo.");
                  } finally {
                    setLoadingUpdate(false);
                    setOpenConfirmationModal(false); // close CustomModal
                    setOpenModal(true); // open Result Modal
                  }
                }}
              />

              {/* Modal to show cancellation */}
              <ResultModal
                open={openModal}
                message={modalMessage}
                onClose={() => setOpenModal(false)}
              />
            </>
          )}


        </div>
      )}
    </>
  );
};

export default WalletPayIn;
